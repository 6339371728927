import { createI18nInstance } from '@volvo/vce-uikit';
import navigation from './i18n/en/navigation.json';
import sitemap from './i18n/en/sitemap.json';
import uikit from './i18n/en/uikit.json';

const resources = {
  en: {
    sitemap,
    navigation,
    uikit,
  },
};

const mapI18n = createI18nInstance({
  initOptions: {
    resources,
    lng: 'en',
    defaultNS: 'sitemap',
    fallbackNS: 'uikit',
    interpolation: {
      escapeValue: false,
    },
  },
});

export default mapI18n;
